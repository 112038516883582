import React, { useState, useMemo } from "react";
import queries from "../helpers/queries";
import { useQuery } from "@apollo/react-hooks";
import { useForm, Controller } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import BannerOverlay from "../Components/BannerOverlay";
import moment from "moment";
import Select from "react-select";
import {
    FcComments,
    FcCapacitor,
    FcConferenceCall,
    FcDepartment,
    FcShop,
    FcNfcSign,
    FcCopyright,
    FcAdvertising,
    FcMinus,
    FcDocument,
    FcCurrencyExchange,
    FcSportsMode,
    FcShipped,
    FcScatterPlot,
    FcInternal,
    FcWiFiLogo,
} from "react-icons/fc";
import { FaArrowRight, FaPlane } from "react-icons/fa";
import "../override.css";
import Modal from "../Components/modal";
import { Country, State } from "country-state-city";
import { useHistory } from "react-router";

const NumberFormatter = new Intl.NumberFormat("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const renderItem = (name, paxValue, itemValue) => {
    if (parseInt(paxValue) !== 0) {
        return (
            <div className="mb-2">
                {parseInt(paxValue)} x {name}: RM
                {NumberFormatter.format(
                    (parseInt(paxValue) || 0) * (itemValue || 0)
                )}
            </div>
        );
    }
    return null;
};

const renderAirfare = (name, paxValue, airfareType) => {
    if (parseInt(paxValue) !== 0) {
        return (
            <div className="mb-2">
                {parseInt(paxValue)} x {name} Airfare: RM
                {NumberFormatter.format(
                    (parseInt(paxValue) || 0) * (airfareType || 0)
                )}
            </div>
        );
    }
    return null;
};

const renderTipping = (name, paxValue, tippingType) => {
    if (parseInt(paxValue) !== 0) {
        return (
            <div className="mb-2">
                {parseInt(paxValue)} x {name} Tipping: RM
                {NumberFormatter.format(
                    (parseInt(paxValue) || 0) * (tippingType || 0)
                )}
            </div>
        );
    }
    return null;
};

const renderPackage = (name, paxValue, groundPackage) => {
    if (parseInt(paxValue) !== 0) {
        return (
            <div className="mb-2">
                {parseInt(paxValue)} x {name} Package: RM
                {NumberFormatter.format(
                    (parseInt(paxValue) || 0) * (groundPackage || 0)
                )}
            </div>
        );
    }
    return null;
};

const renderSupp = (name, paxValue, suppPackage) => {
    if (parseInt(paxValue) !== 0) {
        return (
            <div className="mb-2">
                {parseInt(paxValue)} x {name} Supp: RM
                {NumberFormatter.format(
                    (parseInt(paxValue) || 0) * (suppPackage || 0)
                )}
            </div>
        );
    }
    return null;
};

const formFields = [
    {
        name: "adultPax",
        label: "Adult Pax",
        type: "number",
    },
    {
        name: "childPax",
        label: "Child Pax",
        type: "number",
    },

    {
        name: "childnobedPax",
        label: "Child No Bed Pax",
        type: "number",
    },
    {
        name: "singlePax",
        label: "Single Pax",
        type: "number",
    },
    {
        name: "infantPax",
        label: "Infant Pax",
        type: "number",
    },
];

const memberFields = [
    {
        name: "name",
        label: "Passport Name",
        type: "string",
    },
    {
        name: "dob",
        label: "Date of Birth",
        type: "date",
    },
    {
        name: "passport",
        label: "Passport",
        type: "string",
    },
    {
        name: "phone",
        label: "Phone",
        type: "string",
    },
    {
        name: "remark",
        label: "Remark",
        type: "string",
    },
];

const infantFields = [
    {
        name: "name",
        label: "Passport Name",
        type: "string",
    },
    {
        name: "dob",
        label: "Date of Birth",
        type: "date",
    },
    {
        name: "passport",
        label: "Passport",
        type: "string",
    },
    {
        name: "remark",
        label: "Remark",
        type: "string",
    },
];

const Tours = withRouter(() => {
    const locate = useLocation();
    const path = locate.pathname.split("/")[2];

    const NumberFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    console.log(locate.state);

    const [packages, setPackages] = useState(undefined);
    const [departDates, setDepartDates] = useState(undefined);
    const [arrivalDates, setArrivalDates] = useState(undefined);
    const [activeTour, setActiveTour] = useState(
        locate.state ? locate.state : undefined
    );
    // eslint-disable-next-line no-unused-vars
    const [airlinesData, setAirlinesData] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [tours, setTours] = useState([]);
    const [price, setPrice] = useState([]);
    const [members, setMembers] = useState([]);
    const [createModalState, setCreateModalState] = useState(false);
    const [galleryModal, setGalleryModal] = useState(false);
    const [currentMemberIndex, setCurrentMemberIndex] = useState(0);
    const [totalPax, setTotalPax] = useState(0);

    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
    });
    // eslint-disable-next-line no-unused-vars
    const [filters, setFilters] = useState({
        area: null,
        state: null,
    });

    const history = useHistory();
    const numColumns =
        packages && packages[0] && packages[0].gallery
            ? Math.min(packages[0].gallery.length, 5)
            : 1;

    const { data } = useQuery(queries.FETCH_AIRLINE, {
        variables: {},
        fetchPolicy: "network-only",
        onCompleted: () => setAirlinesData(data.fetchAirlines),
    });

    useQuery(queries.FETCH_TOURS, {
        variables: {
            limit: paginationState.limit,
            page: paginationState.page,
            area: filters.area ? filters.area : "",
            state: filters.state ? filters.state : "",
            package: path ? path : "",
        },
        fetchPolicy: "network-only",
        onCompleted: ({
            fetchPackagesAgent: { pagination, ...fetchPackagesAgent },
        }) => {
            const departDate = fetchPackagesAgent.data[0].tours[0]
                ? fetchPackagesAgent.data[0].tours[0].flights
                    ? fetchPackagesAgent.data[0].tours[0].flights.filter(
                          (flight) => flight.type === "depart"
                      )
                    : ""
                : "";
            const arrivalDate = fetchPackagesAgent.data[0].tours[0]
                ? fetchPackagesAgent.data[0].tours[0].flights
                    ? fetchPackagesAgent.data[0].tours[0].flights.filter(
                          (flight) => flight.type === "arrive"
                      )
                    : ""
                : "";
            setPaginationState(pagination);
            setPackages(fetchPackagesAgent.data);
            setDepartDates(departDate);
            setArrivalDates(arrivalDate);
            if (!activeTour) {
                setActiveTour(fetchPackagesAgent.data[0].tours[0]);
            }
            fetchPackagesAgent.data[0].tours.map((item) => {
                tours.push(item);
                setPrice({
                    adultPrice: item.adultPrice,
                    childPrice: item.childPrice,
                    childNoBedPrice: item.childNoBedPrice,
                    singlePrice: item.singlePrice,
                    infantPrice: item.infantPrice,
                });
                return item;
            });
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    const resetCreateModal = () => {
        setCreateModalState(false);
        resetForm();
    };

    const {
        register,
        errors,
        handleSubmit,
        control,
        getValues,
        reset: resetForm,
    } = useForm({
        defaultValues: {
            childPax: 0,
            adultPax: 0,
            childnobedPax: 0,
            singlePax: 0,
            infantPax: 0,
            name: "",
            designation: undefined,
            dob: undefined,
            passport: "",
            phone: "",
            remark: "",
            type: undefined,
        },
    });

    const customStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),
        }),
        [errors]
    );

    const handleGallerySwipe = (direction) => {
        const totalGallery = packages[0].gallery.length;
        if (direction === "right") {
            const newIndex =
                galleryModal.i + 1 < totalGallery ? galleryModal.i + 1 : 0;
            setGalleryModal({
                gallery: packages[0].gallery[newIndex],
                i: newIndex,
            });
        } else {
            const newIndex =
                galleryModal.i - 1 < 0 ? totalGallery - 1 : galleryModal.i - 1;
            setGalleryModal({
                gallery: packages[0].gallery[newIndex],
                i: newIndex,
            });
        }
    };

    const handleMemberSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        const formValues = getValues();

        const formData = new FormData(e.target);
        const formObject = {};
        let hasUndefinedField = false;

        formData.forEach((value, key) => {
            if (value.trim() === "") {
                hasUndefinedField = true;
                return; // Skip adding empty values
            }
            formObject[key] = value;
        });

        if (hasUndefinedField) {
            alert("Please fill out all fields");
            return; // Exit function if there are undefined fields
        }

        // Concatenate the latest formObject with the current members array
        const updatedMembers = [...members, formObject];

        // Push current member details into members array
        setMembers(updatedMembers);

        // Reset form fields for the next member
        document.getElementById("memberForm").reset();
        setCurrentMemberIndex(currentMemberIndex + 1);

        // Now you have the updatedMembers array with the latest member details included
        if (
            currentMemberIndex ===
            totalPax + parseInt(formValues.infantPax) - 1
        ) {
            const totalPrice =
                activeTour.childPrice * parseInt(formValues.childPax) +
                activeTour.adultPrice * parseInt(formValues.adultPax) +
                activeTour.childNoBedPrice *
                    parseInt(formValues.childnobedPax) +
                activeTour.singlePrice * parseInt(formValues.singlePax) +
                activeTour.infantPrice * parseInt(formValues.infantPax);

            try {
                const payload = {
                    _id: packages[0]._id,
                    members: updatedMembers,
                    slots: totalPax,
                    tourDate: activeTour.tourDate,
                    tour: activeTour._id,
                    price: totalPrice,
                    childPax: parseInt(formValues.childPax),
                    adultPax: parseInt(formValues.adultPax),
                    childnobedPax: parseInt(formValues.childnobedPax),
                    singlePax: parseInt(formValues.singlePax),
                    infantPax: parseInt(formValues.infantPax),
                    infantPrice: activeTour.infantPrice,
                    departDates,
                    arrivalDates,
                    breakdown: activeTour.breakdown,
                };
                history.push("/checkout", {
                    payload,
                });
            } catch (error) {
                console.error("Error creating order:", error);
                setCurrentMemberIndex(0);
                setCreateModalState(false);
                resetCreateModal();
                alert(
                    "Failed to create order. Please check if there are enough slots available."
                );
            }
        }
    };

    const formValues = getValues();

    return (
        <div>
            <BannerOverlay
                imageUrl={packages ? packages[0].image : ""}
                title={packages ? packages[0].name : ""}
            ></BannerOverlay>
            <div className="lg:p-8 p-4 rounded-md w-full lg:grid grid-cols-5">
                <div className="lg:p-6 lg:px-24 p-2 col-span-4">
                    {departDates
                        ? departDates.map((item, i) => {
                              const selectedAirline = airlinesData.find(
                                  (airline) => airline.name === item.airway
                              );
                              const duration = item.endTime - item.startTime;
                              const hours = Math.floor(
                                  duration / (1000 * 60 * 60)
                              );
                              const minutes = Math.floor(
                                  (duration / (1000 * 60)) % 60
                              );

                              const transitduration =
                                  item.transitEndTime - item.transitStartTime;
                              const transithours = Math.floor(
                                  transitduration / (1000 * 60 * 60)
                              );
                              const transitminutes = Math.floor(
                                  (transitduration / (1000 * 60)) % 60
                              );
                              return (
                                  <div
                                      className="grid grid-cols-1 gap-4"
                                      key={i}
                                  >
                                      <div className="block ">
                                          <div className="grid lg:grid-cols-1">
                                              <div className="flex justify-center border-solid bg-white border border-black flex-col col-span-1">
                                                  <div className="w-full border border-black airline-background">
                                                      <div
                                                          className="flex justify-center bg-specialgreen border-solid bg-white flex-col col-span-1"
                                                      >
                                                          <div className="">
                                                              <div className="rounded-lg flex flex-col p-2 py-4">
                                                                  <div className="flex flex-col lg:flex-col">
                                                                      <div className="my-1 text-icongreen text-sm text-center capitalize">
                                                                          <strong>
                                                                              DEPARTURE
                                                                          </strong>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="flex flex-col p-8">
                                                          <div className="flex flex-col lg:flex-col">
                                                              <div className="my-1 text-sm flex items-center capitalize">
                                                                  {selectedAirline ? (
                                                                      selectedAirline.img && (
                                                                          <img
                                                                              src={
                                                                                  selectedAirline
                                                                                      ? selectedAirline.img
                                                                                      : ""
                                                                              }
                                                                              alt={
                                                                                  selectedAirline
                                                                                      ? selectedAirline.name
                                                                                      : ""
                                                                              }
                                                                              className="ml-2"
                                                                              style={{
                                                                                  maxWidth:
                                                                                      "150px",
                                                                                  maxHeight:
                                                                                      "150px",
                                                                              }}
                                                                          />
                                                                      )
                                                                  ) : (
                                                                      <></>
                                                                  )}
                                                                  <div className="ml-4 font-bold">
                                                                      {selectedAirline
                                                                          ? selectedAirline.name
                                                                          : ""}
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <hr className="my-3"></hr>
                                                          <div className="flex items-center mb-4 lg:text-base text-sm">
                                                              <strong className="mr-2">
                                                                  {
                                                                      item.startCity
                                                                  }
                                                              </strong>
                                                              <span className="mr-2">
                                                                  <FaArrowRight />
                                                              </span>
                                                              <strong>
                                                                  {item.endCity}
                                                              </strong>
                                                          </div>

                                                          <div className="mb-4 lg:text-base text-sm">
                                                              {item.code} |{" "}
                                                              {moment(
                                                                  parseInt(
                                                                      item.startTime
                                                                  )
                                                              ).format(
                                                                  "HH:mm"
                                                              )}{" "}
                                                              -{" "}
                                                              {moment(
                                                                  parseInt(
                                                                      item.endTime
                                                                  )
                                                              ).format(
                                                                  "HH:mm"
                                                              )}{" "}
                                                              | {hours} hours{" "}
                                                              {minutes} minutes
                                                          </div>

                                                          <hr />
                                                      </div>
                                                  </div>
                                              </div>
                                              {item.transitStartTime && (
                                                  <div className="w-full border border-black tnc-background">
                                                      <div className="flex flex-col p-8 greyOpaqueBackground">
                                                          <div className="my-1 text-white text-xl capitalize">
                                                              <strong>
                                                                  TRANSIT
                                                              </strong>{" "}
                                                          </div>
                                                          <div className="flex items-center mb-4">
                                                              <strong className="mr-2">
                                                                  {
                                                                      item.transitStartCity
                                                                  }
                                                              </strong>
                                                              <span className="mr-2">
                                                                  <FaArrowRight />
                                                              </span>
                                                              <strong>
                                                                  {
                                                                      item.transitEndCity
                                                                  }
                                                              </strong>
                                                          </div>
                                                          <div className="mb-4">
                                                              {item.code} |{" "}
                                                              {moment(
                                                                  parseInt(
                                                                      item.transitStartTime
                                                                  )
                                                              ).format(
                                                                  "HH:mm"
                                                              )}{" "}
                                                              -{" "}
                                                              {moment(
                                                                  parseInt(
                                                                      item.transitEndTime
                                                                  )
                                                              ).format(
                                                                  "HH:mm"
                                                              )}{" "}
                                                              | {transithours}{" "}
                                                              hours{" "}
                                                              {transitminutes}{" "}
                                                              minutes
                                                          </div>
                                                          <hr />
                                                      </div>
                                                  </div>
                                              )}
                                          </div>
                                      </div>
                                  </div>
                              );
                          })
                        : ""}

                    {arrivalDates
                        ? arrivalDates.map((item, i) => {
                              const selectedAirline = airlinesData.find(
                                  (airline) => airline.name === item.airway
                              );
                              const duration = item.endTime - item.startTime;
                              const hours = Math.floor(
                                  duration / (1000 * 60 * 60)
                              );
                              const minutes = Math.floor(
                                  (duration / (1000 * 60)) % 60
                              );

                              const transitduration =
                                  item.transitEndTime - item.transitStartTime;
                              const transithours = Math.floor(
                                  transitduration / (1000 * 60 * 60)
                              );
                              const transitminutes = Math.floor(
                                  (transitduration / (1000 * 60)) % 60
                              );
                              return (
                                  <div className="grid grid-cols-1 gap-4 mb-12">
                                      <div className="block ">
                                          <div className="grid lg:grid-cols-1">
                                              <div className="flex justify-center border-solid bg-white border border-black flex-col col-span-1">
                                                  <div className="w-full border border-black airline-background">
                                                      <div
                                                          style={{
                                                              width: "200px",
                                                          }}
                                                          className="flex justify-center bg-specialpink border-solid bg-white flex-col col-span-1"
                                                      >
                                                          <div className="">
                                                              <div className="rounded-lg flex flex-col p-2 py-4">
                                                                  <div className="flex flex-col lg:flex-col">
                                                                      <div className="my-1 text-icongreen text-sm text-center capitalize">
                                                                          <strong>
                                                                              ARRIVAL
                                                                          </strong>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="flex flex-col p-8">
                                                          <div className="flex flex-col lg:flex-col">
                                                              <div className="my-1 lg:text-base text-sm flex items-center capitalize">
                                                                  {selectedAirline ? (
                                                                      selectedAirline.img && (
                                                                          <img
                                                                              src={
                                                                                  selectedAirline
                                                                                      ? selectedAirline.img
                                                                                      : ""
                                                                              }
                                                                              alt={
                                                                                  selectedAirline
                                                                                      ? selectedAirline.name
                                                                                      : ""
                                                                              }
                                                                              className="ml-2"
                                                                              style={{
                                                                                  maxWidth:
                                                                                      "150px",
                                                                                  maxHeight:
                                                                                      "150px",
                                                                              }}
                                                                          />
                                                                      )
                                                                  ) : (
                                                                      <></>
                                                                  )}
                                                                  <div className="ml-4 font-bold">
                                                                      {selectedAirline
                                                                          ? selectedAirline.name
                                                                          : ""}
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <hr className="my-3"></hr>
                                                          <div className="flex text-sm items-center mb-4">
                                                              <strong className="mr-2">
                                                                  {
                                                                      item.startCity
                                                                  }
                                                              </strong>
                                                              <span className="mr-2">
                                                                  <FaArrowRight />
                                                              </span>
                                                              <strong>
                                                                  {item.endCity}
                                                              </strong>
                                                          </div>

                                                          <div className="mb-4 lg:text-base text-sm">
                                                              {item.code} |{" "}
                                                              {moment(
                                                                  parseInt(
                                                                      item.startTime
                                                                  )
                                                              ).format(
                                                                  "HH:mm"
                                                              )}{" "}
                                                              -{" "}
                                                              {moment(
                                                                  parseInt(
                                                                      item.endTime
                                                                  )
                                                              ).format(
                                                                  "HH:mm"
                                                              )}{" "}
                                                              | {hours} hours{" "}
                                                              {minutes} minutes
                                                          </div>

                                                          <hr />
                                                      </div>
                                                  </div>
                                              </div>
                                              {item.transitStartTime && (
                                                  <div className="w-full border border-black tnc-background">
                                                      <div className="flex flex-col p-8 greyOpaqueBackground">
                                                          <div className="my-1 text-white text-xl capitalize">
                                                              <strong>
                                                                  TRANSIT
                                                              </strong>{" "}
                                                          </div>
                                                          <div className="flex items-center mb-4">
                                                              <strong className="mr-2">
                                                                  {
                                                                      item.transitStartCity
                                                                  }
                                                              </strong>
                                                              <span className="mr-2">
                                                                  <FaArrowRight />
                                                              </span>
                                                              <strong>
                                                                  {
                                                                      item.transitEndCity
                                                                  }
                                                              </strong>
                                                          </div>
                                                          <div className="mb-4">
                                                              {item.code} |{" "}
                                                              {moment(
                                                                  parseInt(
                                                                      item.transitStartTime
                                                                  )
                                                              ).format(
                                                                  "HH:mm"
                                                              )}{" "}
                                                              -{" "}
                                                              {moment(
                                                                  parseInt(
                                                                      item.transitEndTime
                                                                  )
                                                              ).format(
                                                                  "HH:mm"
                                                              )}{" "}
                                                              | {transithours}{" "}
                                                              hours{" "}
                                                              {transitminutes}{" "}
                                                              minutes
                                                          </div>
                                                          <hr />
                                                      </div>
                                                  </div>
                                              )}
                                          </div>
                                      </div>
                                  </div>
                              );
                          })
                        : ""}
                    <div className="grid grid-cols-1 gap-4 mb-12  tnc-background ">
                        {packages
                            ? packages.map((item, i) => {
                                const CountryList = [
                                    Country.getCountryByCode("JP"),
                                    Country.getCountryByCode("KR"),
                                    Country.getCountryByCode("CN"),
                                    Country.getCountryByCode("TW"),
                                    Country.getCountryByCode("IN"),
                                    Country.getCountryByCode("TH"),
                                    Country.getCountryByCode("VN"),
                                ];
                                  const selectedCountry = CountryList.find(
                                      (outlet) =>
                                          outlet.isoCode === item.country
                                  );

                                  const states = State.getAllStates();
                                  const selectedStates = states.find(
                                      (outlet) => outlet.isoCode === item.state
                                  );
                                  return (
                                      <div
                                          className="block border greyOpaqueBackground border-black"
                                          key={i}
                                      >
                                          <div
                                              className="grid lg:grid-cols-1"
                                              key={i}
                                          >
                                              <div className="flex justify-center border-solid border border-black flex-col col-span-1">
                                                  <div className="w-full rounded">
                                                      <div className="rounded-lg flex flex-col p-8">
                                                          <div className="flex flex-col lg:flex-col">
                                                              <div className="my-1 text-white text-lg lg:text-xl capitalize">
                                                                  <strong>
                                                                      {
                                                                          item.name
                                                                      }
                                                                  </strong>{" "}
                                                              </div>
                                                              <div className="my-1 text-white text-lg lg:text-xl capitalize">
                                                                  <strong>
                                                                      {
                                                                          item.cnName
                                                                      }
                                                                  </strong>{" "}
                                                              </div>

                                                              <div className="my-1 text-white text-sm capitalize">
                                                                  {selectedCountry
                                                                      ? selectedCountry.name
                                                                      : ""}
                                                              </div>
                                                              <div className="my-1 text-white text-sm capitalize">
                                                                  {selectedStates
                                                                      ? selectedStates.name
                                                                      : ""}
                                                              </div>
                                                          </div>
                                                          <hr className="my-3"></hr>
                                                          <div className="my-1 mb-4 text-white text-sm capitalize">
                                                              <div>
                                                                  <strong>
                                                                      Departure
                                                                      Dates
                                                                  </strong>
                                                              </div>
                                                              <div className="lg:flex">
                                                                  {tours
                                                                      ? tours
                                                                            .slice()
                                                                            .sort(
                                                                                (
                                                                                    a,
                                                                                    b
                                                                                ) =>
                                                                                    parseInt(
                                                                                        a.tourDate
                                                                                    ) -
                                                                                    parseInt(
                                                                                        b.tourDate
                                                                                    )
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    item,
                                                                                    i
                                                                                ) => (
                                                                                    <div
                                                                                        key={
                                                                                            i
                                                                                        }
                                                                                        className={`mt-2 my-2 px-12 py-4 text-sm cursor-pointer border border-black capitalize hover:bg-icongreen hover:text-white ${
                                                                                            activeTour._id ===
                                                                                            item._id
                                                                                                ? "bg-icongreen text-white"
                                                                                                : "bg-yellow-600 text-black"
                                                                                        } font-bold`}
                                                                                        onClick={() => {
                                                                                            window.scrollTo(
                                                                                                {
                                                                                                    top: 0,
                                                                                                    behavior:
                                                                                                        "smooth", // Optional: Adds smooth scrolling effect
                                                                                                }
                                                                                            );
                                                                                            setDepartDates(
                                                                                                item.flights.filter(
                                                                                                    (
                                                                                                        flight
                                                                                                    ) =>
                                                                                                        flight.type ===
                                                                                                        "depart"
                                                                                                )
                                                                                            );
                                                                                            setArrivalDates(
                                                                                                item.flights.filter(
                                                                                                    (
                                                                                                        flight
                                                                                                    ) =>
                                                                                                        flight.type ===
                                                                                                        "arrive"
                                                                                                )
                                                                                            );
                                                                                            setPrice(
                                                                                                {
                                                                                                    adultPrice:
                                                                                                        item.adultPrice,
                                                                                                    childPrice:
                                                                                                        item.childPrice,
                                                                                                    childNoBedPrice:
                                                                                                        item.childNoBedPrice,
                                                                                                    singlePrice:
                                                                                                        item.singlePrice,
                                                                                                    infantPrice:
                                                                                                        item.infantPrice,
                                                                                                }
                                                                                            );
                                                                                            setActiveTour(
                                                                                                item
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <div className="text-center">
                                                                                            {moment(
                                                                                                parseInt(
                                                                                                    item.tourDate
                                                                                                )
                                                                                            ).format(
                                                                                                "DD-MM-YYYY"
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="text-center">
                                                                                            From
                                                                                        </div>
                                                                                        <div className="text-center">
                                                                                            RM{" "}
                                                                                            {NumberFormatter.format(
                                                                                                item.adultPrice
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )
                                                                      : ""}
                                                              </div>
                                                          </div>
                                                          <hr className="my-3"></hr>
                                                          <div className="my-1 mb-4 text-white text-sm capitalize">
                                                              <div>
                                                                  <strong>
                                                                      Prices
                                                                  </strong>
                                                              </div>
                                                              <div className="lg:flex flex-wrap gap-4">
                                                                  <div className="flex-1 max-w-1/5 mt-2 px-4 py-2 border border-gray-400 bg-gradient-to-r from-yellow-400 to-orange-500 text-black font-bold">
                                                                      <div className="text-center">
                                                                          Adult
                                                                          Price:
                                                                      </div>
                                                                      <div className="text-center">
                                                                          RM{" "}
                                                                          {NumberFormatter.format(
                                                                              price.adultPrice
                                                                          )}
                                                                      </div>
                                                                  </div>
                                                                  <div className="flex-1 max-w-1/5 mt-2 px-4 py-2 border border-gray-400 bg-gradient-to-r from-red-400 to-yellow-500 text-black font-bold">
                                                                      <div className="text-center">
                                                                          Child
                                                                          Price:
                                                                      </div>
                                                                      <div className="text-center">
                                                                          RM{" "}
                                                                          {NumberFormatter.format(
                                                                              price.childPrice
                                                                          )}
                                                                      </div>
                                                                  </div>
                                                                  <div className="flex-1 max-w-1/5 mt-2 px-4 py-2 border border-gray-400 bg-gradient-to-r from-yellow-400 to-orange-500 text-black font-bold">
                                                                      <div className="text-center">
                                                                          Child
                                                                          No Bed
                                                                          :
                                                                      </div>
                                                                      <div className="text-center">
                                                                          RM{" "}
                                                                          {NumberFormatter.format(
                                                                              price.childNoBedPrice
                                                                          )}
                                                                      </div>
                                                                  </div>
                                                                  <div className="flex-1 max-w-1/5 mt-2 px-4 py-2 border border-gray-400 bg-gradient-to-r from-red-400 to-yellow-500 text-black font-bold">
                                                                      <div className="text-center">
                                                                          Single
                                                                          Price:
                                                                      </div>
                                                                      <div className="text-center">
                                                                          RM{" "}
                                                                          {NumberFormatter.format(
                                                                              price.singlePrice
                                                                                  ? price.singlePrice
                                                                                  : 0
                                                                          )}
                                                                      </div>
                                                                  </div>
                                                                  <div className="flex-1 max-w-1/5 mt-2 px-4 py-2 border border-gray-400 bg-gradient-to-r from-red-400 to-yellow-500 text-black font-bold">
                                                                      <div className="text-center">
                                                                          Infant
                                                                          Price:
                                                                      </div>
                                                                      <div className="text-center">
                                                                          RM{" "}
                                                                          {NumberFormatter.format(
                                                                              price.infantPrice
                                                                                  ? price.infantPrice
                                                                                  : 0
                                                                          )}
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>

                                                          <hr className="my-3"></hr>
                                                          <div className="my-1 text-white text-xl capitalize">
                                                              <strong>
                                                                  Amenities
                                                              </strong>{" "}
                                                          </div>
                                                          <div className="grid grid-cols-1 lg:grid-cols-5 row-gap-4 mb-8">
                                                              {activeTour
                                                                  .amenities
                                                                  .group ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcCapacitor className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Group
                                                                                  Departure
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .insurance ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcConferenceCall className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Insurance
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .tax ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcDocument className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Airport
                                                                                  Taxes
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .checkin ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcCopyright className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Check-in
                                                                                  baggage
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .tourleader ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcAdvertising className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Tour
                                                                                  Leader
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .hotel ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcDepartment className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Hotel
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .meals ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcShop className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Meals
                                                                                  Onboard
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .wifi ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcWiFiLogo className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Wifi
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .gratuties ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcComments className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Tipping
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .fullpayment ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcCurrencyExchange className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Full
                                                                                  Payment
                                                                                  Required
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .accessible ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcSportsMode className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Accessibility
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .facilities ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcNfcSign className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Facilities
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .pool ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcScatterPlot className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Pool
                                                                                  Access
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .driver ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcShipped className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  Driver
                                                                                  Provided
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}

                                                              {activeTour
                                                                  .amenities
                                                                  .allin ===
                                                              "active" ? (
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          <div className="flex items-center text-white">
                                                                              <FcInternal className="mr-2" />
                                                                              <div className="font-bold">
                                                                                  All
                                                                                  In
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}
                                                          </div>

                                                          {activeTour.amenities &&
                                                              Array.isArray(
                                                                  activeTour.amenities
                                                              ) &&
                                                              activeTour.amenities.some(
                                                                  (amenity) =>
                                                                      amenity.group ===
                                                                      "inactive"
                                                              ) && (
                                                                  <>
                                                                      <hr className="my-3"></hr>
                                                                      <div className="my-1 text-white text-xl capitalize">
                                                                          <strong>
                                                                              NOT
                                                                              Included
                                                                          </strong>{" "}
                                                                      </div>
                                                                      <div className="grid grid-cols-1 lg:grid-cols-5 row-gap-4 mb-8">
                                                                          {activeTour
                                                                              .amenities
                                                                              .group ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcCapacitor className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Group
                                                                                              Departure
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .insurance ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcConferenceCall className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Insurance
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .tax ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcDocument className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Airport
                                                                                              Taxes
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .checkin ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcCopyright className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Check-in
                                                                                              baggage
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .tourleader ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcAdvertising className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Tour
                                                                                              Leader
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .hotel ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcDepartment className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Hotel
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .meals ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcShop className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Meals
                                                                                              Onboard
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .wifi ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcWiFiLogo className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Wifi
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .gratuties ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcComments className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Tipping
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .fullpayment ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcCurrencyExchange className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Full
                                                                                              Payment
                                                                                              Required
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .accessible ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcSportsMode className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Accessibility
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .facilities ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcNfcSign className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Facilities
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .pool ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcScatterPlot className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Pool
                                                                                              Access
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .driver ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcShipped className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              Driver
                                                                                              Provided
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}

                                                                          {activeTour
                                                                              .amenities
                                                                              .allin ===
                                                                          "inactive" ? (
                                                                              <div className="">
                                                                                  <div className="py-4">
                                                                                      <div className="flex items-center text-white">
                                                                                          <FcInternal className="mr-2" />
                                                                                          <div className="font-bold">
                                                                                              All
                                                                                              In
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          ) : (
                                                                              ""
                                                                          )}
                                                                      </div>
                                                                  </>
                                                              )}

                                                          <hr className="my-3"></hr>
                                                          <div className="my-1 text-white text-xl capitalize">
                                                              <strong>
                                                                  Tour Details
                                                              </strong>{" "}
                                                          </div>
                                                          {item && (
                                                              <div className="grid grid-cols-1 lg:grid-cols-1 row-gap-4 mb-8">
                                                                  <div className="">
                                                                      <div className="py-4">
                                                                          {item.assemblyTime && (
                                                                              <div className="flex items-center text-white">
                                                                                  <FcMinus className="mr-2" />
                                                                                  <div className="font-bold">
                                                                                      Assembly
                                                                                      Time:{" "}
                                                                                      {
                                                                                          item.assemblyTime
                                                                                      }
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                          {item.meetupPoint && (
                                                                              <div className="flex items-center text-white">
                                                                                  <FcMinus className="mr-2" />
                                                                                  <div className="font-bold">
                                                                                      Meet
                                                                                      Up
                                                                                      Point:{" "}
                                                                                      {
                                                                                          item.meetupPoint
                                                                                      }
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                          {item.tourguideName && (
                                                                              <div className="flex items-center text-white">
                                                                                  <FcMinus className="mr-2" />
                                                                                  <div className="font-bold">
                                                                                      Tourguide
                                                                                      Name:{" "}
                                                                                      {
                                                                                          item.tourguideName
                                                                                      }
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                          {item.tourguideChineseName && (
                                                                              <div className="flex items-center text-white">
                                                                                  <FcMinus className="mr-2" />
                                                                                  <div className="font-bold">
                                                                                      Tourguide
                                                                                      Chinese
                                                                                      Name:{" "}
                                                                                      {
                                                                                          item.tourguideChineseName
                                                                                      }
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                          {item.tourguidePhone && (
                                                                              <div className="flex items-center text-white">
                                                                                  <FcMinus className="mr-2" />
                                                                                  <div className="font-bold">
                                                                                      Tourguide
                                                                                      Phone:{" "}
                                                                                      {
                                                                                          item.tourguidePhone
                                                                                      }
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                          {item.handcarryWeight && (
                                                                              <div className="flex items-center text-white">
                                                                                  <FcMinus className="mr-2" />
                                                                                  <div className="font-bold">
                                                                                      Handcarry
                                                                                      Weight:{" "}
                                                                                      {
                                                                                          item.handcarryWeight
                                                                                      }{" "}
                                                                                      kg
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                          {item.luggageWeight && (
                                                                              <div className="flex items-center text-white">
                                                                                  <FcMinus className="mr-2" />
                                                                                  <div className="font-bold">
                                                                                      Luggage
                                                                                      Weight:{" "}
                                                                                      {
                                                                                          item.luggageWeight
                                                                                      }{" "}
                                                                                      kg
                                                                                  </div>
                                                                              </div>
                                                                          )}
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )}

                                                          <hr className="my-3"></hr>
                                                          <div className="my-1 text-white text-xl capitalize">
                                                              <strong>
                                                                  Remarks
                                                              </strong>{" "}
                                                          </div>

                                                          <div className="grid grid-cols-1 lg:grid-cols-1 row-gap-4 mb-8">
                                                              <div className="py-4">
                                                                  {packages
                                                                      ? packages[0].attributes.map(
                                                                            (
                                                                                item,
                                                                                i
                                                                            ) => {
                                                                                return (
                                                                                    <div
                                                                                        key={
                                                                                            i
                                                                                        }
                                                                                        className=""
                                                                                    >
                                                                                        <div className="flex items-center text-white">
                                                                                            <FcMinus className="mr-2" />
                                                                                            <div className="font-bold">
                                                                                                {
                                                                                                    item.attribute
                                                                                                }

                                                                                                :{" "}
                                                                                                {
                                                                                                    item.value
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )
                                                                      : ""}
                                                              </div>
                                                          </div>
                                                          <div className="lg:flex">
                                                              {item.itinerary ? (
                                                                  <div
                                                                      onClick={() =>
                                                                          window.open(
                                                                              item.itinerary,
                                                                              "_blank"
                                                                          )
                                                                      }
                                                                      className="mt-2 mr-2 my-2 p-1 py-4 w-40 text-sm text-catskill hover:text-black hover:bg-specialgreen bg-icongreen cursor-pointer rounded-lg flex justify-center capitalize"
                                                                  >
                                                                      Itinery
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}
                                                              {item.video ? (
                                                                  <div
                                                                      onClick={() =>
                                                                          window.open(
                                                                              item.video,
                                                                              "_blank"
                                                                          )
                                                                      }
                                                                      className="mt-2 mr-2 my-2 p-1 py-4 w-40 text-sm text-catskill hover:text-black hover:bg-specialpink bg-iconpink cursor-pointer rounded-lg flex justify-center capitalize"
                                                                  >
                                                                      Video
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })
                            : ""}
                    </div>
                </div>
                <div className="lg:py-6 py-2 col-span-1">
                    <div className="grid grid-cols-1 gap-4 mb-12  tnc-background ">
                        {packages
                            ? packages.map((item, i) => {
                                  return (
                                      <div
                                          className="block border greyOpaqueBackground border-black"
                                          key={i}
                                      >
                                          <div
                                              className="grid lg:grid-cols-1"
                                              key={i}
                                          >
                                              <div className="flex justify-center border-solid border border-black flex-col col-span-1">
                                                  <div className="w-full rounded">
                                                      <div className="rounded-lg flex flex-col p-8">
                                                          <div className="flex text-sm flex-col lg:flex-col">
                                                              <div className="my-1 text-white text-xl capitalize mb-2">
                                                                  <strong>
                                                                      Summary
                                                                  </strong>{" "}
                                                              </div>
                                                              <div className="flex flex-col lg:flex-col">
                                                                  <div className="flex items-center mb-2">
                                                                      <FaPlane className="mr-2 text-3xl"></FaPlane>
                                                                      <div className="flex-grow text-sm">
                                                                          <>
                                                                              DEPARTURE
                                                                              DATE:{" "}
                                                                          </>
                                                                          <br />
                                                                          {moment(
                                                                              parseInt(
                                                                                  activeTour
                                                                                      ? activeTour.tourDate
                                                                                      : ""
                                                                              )
                                                                          ).format(
                                                                              "DD-MM-YYYY"
                                                                          )}
                                                                      </div>
                                                                  </div>
                                                                  <div className="mb-2">
                                                                      <>
                                                                          Tour
                                                                          Code:{" "}
                                                                          {activeTour
                                                                              ? activeTour.tourCode
                                                                              : ""}
                                                                      </>
                                                                  </div>
                                                              </div>
                                                              <div
                                                                  onSubmit={handleSubmit()}
                                                                  className=""
                                                              >
                                                                  {formFields.map(
                                                                      (
                                                                          item,
                                                                          i
                                                                      ) => (
                                                                          <div
                                                                              key={
                                                                                  item.name
                                                                              }
                                                                          >
                                                                              <div className="mb-2 text-sm text-lynch font-medium">
                                                                                  {
                                                                                      item.label
                                                                                  }
                                                                              </div>
                                                                              <input
                                                                                  className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                                                      errors[
                                                                                          item
                                                                                              .name
                                                                                      ]
                                                                                          ? "border-red-400"
                                                                                          : "border-gray-300"
                                                                                  }`}
                                                                                  name={
                                                                                      item.name
                                                                                  }
                                                                                  ref={register(
                                                                                      {
                                                                                          required: true,
                                                                                      }
                                                                                  )}
                                                                                  placeholder={
                                                                                      item.label
                                                                                  }
                                                                                  type={
                                                                                      item.type
                                                                                          ? item.type
                                                                                          : "text"
                                                                                  }
                                                                                  disabled={
                                                                                      item.disabled
                                                                                  }
                                                                                  onChange={() => {
                                                                                      const formValues =
                                                                                          getValues();
                                                                                      setTotalPax(
                                                                                          (parseInt(
                                                                                              formValues.childPax
                                                                                          ) ||
                                                                                              0) +
                                                                                              (parseInt(
                                                                                                  formValues.adultPax
                                                                                              ) ||
                                                                                                  0) +
                                                                                              (parseInt(
                                                                                                  formValues.childnobedPax
                                                                                              ) ||
                                                                                                  0) +
                                                                                              (parseInt(
                                                                                                  formValues.singlePax
                                                                                              ) ||
                                                                                                  0) +
                                                                                              (parseInt(
                                                                                                  formValues.infantPax
                                                                                              ) ||
                                                                                                  0)
                                                                                      );
                                                                                  }}
                                                                                  min="0"
                                                                              />
                                                                              {errors[
                                                                                  item
                                                                                      .name
                                                                              ] && (
                                                                                  <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                                                              )}
                                                                          </div>
                                                                      )
                                                                  )}
                                                                  <div className="flex flex-col lg:flex-col">
                                                                      <div className="my-1 text-white text-xl capitalize mb-2">
                                                                          <strong>
                                                                              Breakdown
                                                                          </strong>{" "}
                                                                      </div>
                                                                      {totalPax >
                                                                          0 && (
                                                                          <>
                                                                              <div className="mb-6">
                                                                                  <>
                                                                                      {renderPackage(
                                                                                          "Child",
                                                                                          formValues.childPax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .childGroundPackage,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .childSupp
                                                                                      )}

                                                                                      {renderPackage(
                                                                                          "Adult",
                                                                                          formValues.adultPax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .groundPackage,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .supp
                                                                                      )}

                                                                                      {renderPackage(
                                                                                          "Child No Bed",
                                                                                          formValues.childnobedPax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .childNoBedGroundPackage,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .childNoBedSupp
                                                                                      )}

                                                                                      {renderPackage(
                                                                                          "Single",
                                                                                          formValues.singlePax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .singleGroundPackage,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .singleSupp
                                                                                      )}
                                                                                  </>
                                                                              </div>

                                                                              <div className="mb-6">
                                                                                  <>
                                                                                      {renderSupp(
                                                                                          "Child",
                                                                                          formValues.childPax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .childSupp
                                                                                      )}

                                                                                      {renderSupp(
                                                                                          "Adult",
                                                                                          formValues.adultPax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .supp
                                                                                      )}

                                                                                      {renderSupp(
                                                                                          "Child No Bed",
                                                                                          formValues.childnobedPax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .childNoBedSupp
                                                                                      )}

                                                                                      {renderSupp(
                                                                                          "Single",
                                                                                          formValues.singlePax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .singleSupp
                                                                                      )}
                                                                                  </>
                                                                              </div>

                                                                              <div className="mb-6">
                                                                                  <>
                                                                                      {renderAirfare(
                                                                                          "Child",
                                                                                          formValues.childPax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .childAirticket
                                                                                      )}

                                                                                      {renderAirfare(
                                                                                          "Adult",
                                                                                          formValues.adultPax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .airticket
                                                                                      )}

                                                                                      {renderAirfare(
                                                                                          "Child No Bed",
                                                                                          formValues.childnobedPax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .childNoBedAirticket
                                                                                      )}

                                                                                      {renderAirfare(
                                                                                          "Single",
                                                                                          formValues.singlePax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .singleAirticket
                                                                                      )}
                                                                                  </>
                                                                              </div>

                                                                              <div className="mb-6">
                                                                                  <>
                                                                                      {renderTipping(
                                                                                          "Child",
                                                                                          formValues.childPax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .childTipping
                                                                                      )}

                                                                                      {renderTipping(
                                                                                          "Adult",
                                                                                          formValues.adultPax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .tipping
                                                                                      )}

                                                                                      {renderTipping(
                                                                                          "Child No Bed",
                                                                                          formValues.childnobedPax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .childNoBedTipping
                                                                                      )}

                                                                                      {renderTipping(
                                                                                          "Single",
                                                                                          formValues.singlePax,
                                                                                          activeTour
                                                                                              .breakdown
                                                                                              .singleTipping
                                                                                      )}
                                                                                  </>
                                                                              </div>

                                                                              <div className="mb-6">
                                                                                  {/* Child Insurance */}
                                                                                  {renderItem(
                                                                                      "Child Insurance",
                                                                                      formValues.childPax,
                                                                                      activeTour
                                                                                          .breakdown
                                                                                          .childInsurance
                                                                                  )}

                                                                                  {/* Adult Insurance */}
                                                                                  {renderItem(
                                                                                      "Adult Insurance",
                                                                                      formValues.adultPax,
                                                                                      activeTour
                                                                                          .breakdown
                                                                                          .adultInsurance
                                                                                  )}

                                                                                  {/* Child No Bed Insurance */}
                                                                                  {renderItem(
                                                                                      "Child No Bed Insurance",
                                                                                      formValues.childnobedPax,
                                                                                      activeTour
                                                                                          .breakdown
                                                                                          .childNoBedInsurance
                                                                                  )}

                                                                                  {/* Single Insurance */}
                                                                                  {renderItem(
                                                                                      "Single Insurance",
                                                                                      formValues.singlePax,
                                                                                      activeTour
                                                                                          .breakdown
                                                                                          .singleInsurance
                                                                                  )}
                                                                              </div>

                                                                              <div className="mb-6">
                                                                                  {/* Child Visa */}
                                                                                  {renderItem(
                                                                                      "Child Visa",
                                                                                      formValues.childPax,
                                                                                      activeTour
                                                                                          .breakdown
                                                                                          .childVisa
                                                                                  )}

                                                                                  {/* Adult Visa */}
                                                                                  {renderItem(
                                                                                      "Adult Visa",
                                                                                      formValues.adultPax,
                                                                                      activeTour
                                                                                          .breakdown
                                                                                          .visa
                                                                                  )}

                                                                                  {/* Child No Bed Visa */}
                                                                                  {renderItem(
                                                                                      "Child No Bed Visa",
                                                                                      formValues.childnobedPax,
                                                                                      activeTour
                                                                                          .breakdown
                                                                                          .childNoBedVisa
                                                                                  )}

                                                                                  {/* Single Visa */}
                                                                                  {renderItem(
                                                                                      "Single Visa",
                                                                                      formValues.singlePax,
                                                                                      activeTour
                                                                                          .breakdown
                                                                                          .singleVisa
                                                                                  )}

                                                                                  {renderItem(
                                                                                      "Infant Price",
                                                                                      formValues.infantPax,
                                                                                      activeTour.infantPrice
                                                                                  )}
                                                                              </div>
                                                                          </>
                                                                      )}

                                                                      <div
                                                                          className="mb-2"
                                                                          onClick={() =>
                                                                              console.log(
                                                                                  formValues,
                                                                                  activeTour
                                                                              )
                                                                          }
                                                                      >
                                                                          <>
                                                                              Total:
                                                                              RM
                                                                              {NumberFormatter.format(
                                                                                  (activeTour.childPrice ||
                                                                                      0) *
                                                                                      parseInt(
                                                                                          formValues.childPax
                                                                                              ? formValues.childPax
                                                                                              : 0
                                                                                      ) +
                                                                                      (activeTour.adultPrice ||
                                                                                          0) *
                                                                                          parseInt(
                                                                                              formValues.adultPax
                                                                                                  ? formValues.adultPax
                                                                                                  : 0
                                                                                          ) +
                                                                                      (activeTour.childNoBedPrice ||
                                                                                          0) *
                                                                                          parseInt(
                                                                                              formValues.childnobedPax
                                                                                                  ? formValues.childnobedPax
                                                                                                  : 0
                                                                                          ) +
                                                                                      (activeTour.singlePrice ||
                                                                                          0) *
                                                                                          parseInt(
                                                                                              formValues.singlePax
                                                                                                  ? formValues.singlePax
                                                                                                  : 0
                                                                                          ) +
                                                                                      (activeTour.infantPrice ||
                                                                                          0) *
                                                                                          parseInt(
                                                                                              formValues.infantPax
                                                                                                  ? formValues.infantPax
                                                                                                  : 0
                                                                                          )
                                                                              )}
                                                                          </>
                                                                      </div>
                                                                  </div>

                                                                  <button
                                                                      className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white"
                                                                      onClick={() => {
                                                                          if (
                                                                              parseInt(
                                                                                  formValues.infantPax
                                                                              ) >
                                                                              parseInt(
                                                                                  totalPax -
                                                                                      formValues.infantPax
                                                                              )
                                                                          ) {
                                                                              alert(
                                                                                  "Each infant much be accompanied by an adult. Please ensure the number of infants is less than the total number of pax."
                                                                              );
                                                                              return;
                                                                          }

                                                                          if (
                                                                              activeTour.slots <
                                                                              parseInt(
                                                                                  totalPax -
                                                                                      formValues.infantPax
                                                                              )
                                                                          ) {
                                                                              alert(
                                                                                  "Not enough slots available"
                                                                              );
                                                                              return;
                                                                          }
                                                                          setTotalPax(
                                                                              (parseInt(
                                                                                  formValues.childPax
                                                                              ) ||
                                                                                  0) +
                                                                                  (parseInt(
                                                                                      formValues.adultPax
                                                                                  ) ||
                                                                                      0) +
                                                                                  (parseInt(
                                                                                      formValues.childnobedPax
                                                                                  ) ||
                                                                                      0) +
                                                                                  (parseInt(
                                                                                      formValues.singlePax
                                                                                  ) ||
                                                                                      0)
                                                                          );
                                                                          setCreateModalState(
                                                                              true
                                                                          );
                                                                      }}
                                                                  >
                                                                      BOOK NOW
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })
                            : ""}
                    </div>
                </div>
            </div>
            <div className={`w-full grid grid-cols-${numColumns}`}>
                {packages && packages[0] && packages[0].gallery ? (
                    packages[0].gallery.map((gallery, i) => (
                        <div
                            key={i}
                            className={`col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 aspect-w-1 aspect-h-1 bg-black`}
                        >
                            <img
                                src={gallery.img}
                                alt={`Img ${i + 1}`}
                                className="object-cover w-full h-full cursor-pointer"
                                onClick={() => {
                                    setGalleryModal({ gallery, i });
                                }}
                            />
                        </div>
                    ))
                ) : (
                    <p>No images available</p>
                )}
            </div>
            {createModalState && (
                <Modal reset={resetCreateModal}>
                    {currentMemberIndex >= totalPax && (
                        <div className="container h-full mx-auto flex justify-center items-center">
                            <div className="panel bg-white p-6 rounded-md">
                                <div className="mb-4 text-3xl font-bold">
                                    Infant Details
                                </div>
                                <form
                                    id="memberForm"
                                    onSubmit={handleMemberSubmit}
                                    className="lg:w-104"
                                >
                                    {/* Render member details form */}
                                    <div className="mb-2 text-sm text-lynch font-medium">
                                        Passenger {currentMemberIndex + 1}{" "}
                                        Details
                                    </div>
                                    {infantFields.map((item, i) => {
                                        return (
                                            <div key={item.name}>
                                                <div className="mb-2 text-sm text-lynch font-medium">
                                                    {item.label}
                                                </div>
                                                <input
                                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                        errors[item.name]
                                                            ? "border-red-400"
                                                            : "border-gray-300"
                                                    }`}
                                                    name={item.name}
                                                    ref={register({
                                                        required: true, // Ensure field is required
                                                    })}
                                                    placeholder={item.label}
                                                    type={
                                                        item.type
                                                            ? item.type
                                                            : "text"
                                                    }
                                                />
                                                {errors[item.name] &&
                                                    errors[item.name].type ===
                                                        "required" && (
                                                        <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                                    )}
                                            </div>
                                        );
                                    })}
                                    {currentMemberIndex <
                                        totalPax +
                                            parseInt(formValues.infantPax) -
                                            1 && (
                                        <button
                                            type="submit"
                                            className="mt-4 py-3 text-sm rounded-lg w-full bg-green-500 text-white"
                                        >
                                            Next
                                        </button>
                                    )}
                                    {currentMemberIndex ===
                                        totalPax +
                                            parseInt(formValues.infantPax) -
                                            1 && (
                                        <button
                                            type="submit"
                                            className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 text-white"
                                        >
                                            Submit
                                        </button>
                                    )}
                                </form>
                            </div>
                        </div>
                    )}
                    {currentMemberIndex + 1 <= totalPax && (
                        <div className="container h-full mx-auto flex justify-center items-center">
                            <div className="panel bg-white p-6 rounded-md">
                                <div className="mb-4 text-3xl font-bold">
                                    Member Details
                                </div>
                                <form
                                    id="memberForm"
                                    onSubmit={handleMemberSubmit}
                                    className="lg:w-104"
                                >
                                    {/* Render member details form */}
                                    <div className="mb-2 text-sm text-lynch font-medium">
                                        Passenger {currentMemberIndex + 1}{" "}
                                        Details
                                    </div>
                                    <div className="mb-2 text-sm text-lynch font-medium">
                                        Designation
                                    </div>
                                    <Controller
                                        as={
                                            <Select
                                                styles={customStyles}
                                                isSearchable={false}
                                            />
                                        }
                                        name="designation"
                                        control={control}
                                        rules={{ required: true }}
                                        options={[
                                            {
                                                label: "Miss",
                                                value: "Miss",
                                            },
                                            {
                                                label: "Mr",
                                                value: "Mr",
                                            },
                                            {
                                                label: "Ms",
                                                value: "Ms",
                                            },
                                            {
                                                label: "Mstr",
                                                value: "Mstr",
                                            },
                                        ]}
                                    />
                                    {errors.designation && (
                                        <p className="text-red-400 text-xs">{`Designation cannot be empty`}</p>
                                    )}
                                    {memberFields.map((item, i) => {
                                        return (
                                            <div key={item.name}>
                                                <div className="mb-2 text-sm text-lynch font-medium">
                                                    {item.label}
                                                </div>
                                                <input
                                                    className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                                        errors[item.name]
                                                            ? "border-red-400"
                                                            : "border-gray-300"
                                                    }`}
                                                    name={item.name}
                                                    ref={register({
                                                        required: true, // Ensure field is required
                                                    })}
                                                    placeholder={item.label}
                                                    type={
                                                        item.type
                                                            ? item.type
                                                            : "text"
                                                    }
                                                />
                                                {errors[item.name] &&
                                                    errors[item.name].type ===
                                                        "required" && (
                                                        <p className="text-red-400 text-xs">{`${item.label} cannot be empty`}</p>
                                                    )}
                                            </div>
                                        );
                                    })}
                                    {currentMemberIndex + 1 <= totalPax &&
                                        parseInt(formValues.infantPax) !==
                                            0 && (
                                            <button
                                                type="submit"
                                                className="mt-4 py-3 text-sm rounded-lg w-full bg-green-500 text-white"
                                            >
                                                Next
                                            </button>
                                        )}

                                    {currentMemberIndex === totalPax - 1 &&
                                        parseInt(formValues.infantPax) ===
                                            0 && (
                                            <button
                                                type="submit"
                                                className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 text-white"
                                            >
                                                Submit
                                            </button>
                                        )}

                                    {currentMemberIndex < totalPax - 1 &&
                                        parseInt(formValues.infantPax) ===
                                            0 && (
                                            <button
                                                type="submit"
                                                className="mt-4 py-3 text-sm rounded-lg w-full bg-green-500 text-white"
                                            >
                                                Next
                                            </button>
                                        )}
                                </form>
                            </div>
                        </div>
                    )}
                </Modal>
            )}

            {galleryModal && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
                    <div
                        className="absolute top-0 left-0 w-full h-full bg-black opacity-100"
                        onClick={() => setGalleryModal(false)}
                    ></div>
                    <div className="relative flex justify-center items-center w-full h-full">
                        <div
                            className="absolute top-0 right-0 m-4 text-xl cursor-pointer z-50 bg-yellow-400 p-2 font-bold text-black"
                            onClick={() => setGalleryModal(false)}
                        >
                            Close Gallery
                        </div>
                        <div className="relative w-full max-w-lg">
                            <img
                                src={
                                    packages
                                        ? packages[0].gallery[galleryModal.i]
                                              .img
                                        : ""
                                }
                                alt={`Gallery ${galleryModal.i + 1}`}
                                className="object-cover w-full h-full z-50"
                                onClick={() => setGalleryModal(galleryModal)}
                            />
                        </div>
                        <div className="absolute top-0 bottom-0 flex justify-between items-center w-full px-4">
                            <div
                                className="w-12 h-12 flex items-center justify-center bg-white rounded-full cursor-pointer opacity-50 z-50"
                                onClick={() => handleGallerySwipe("left")}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M15 19l-7-7 7-7"
                                    />
                                </svg>
                            </div>
                            <div
                                className="w-12 h-12 flex items-center justify-center bg-white rounded-full cursor-pointer opacity-50 z-50"
                                onClick={() => handleGallerySwipe("right")}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

export default Tours;
