import styled from "styled-components";
import { mobile } from "../responsive";

const Image = styled.img`
    object-fit: cover;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    background-position: center center;
`;

const Header = styled.div`
    height: 60vh;
    width: 100vw;
    background: black;
    position: relative;
    overflow: hidden;
    ${mobile({ height: "40vh" })}
`;

const Title = styled.h1`
    text-transform: uppercase;
    padding: 1rem;
    font-family: FuturaPT-Bold, sans-serif;
    font-size: 3.5rem;
    margin: 0;
    color: white;
    text-align: center;
    letter-spacing: 0.1rem;
    ${mobile({ fontSize: "2rem", padding: "0.5rem" })}
`;

const Section = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: "Roboto", sans-serif;
    padding: 0 1rem;
    ${mobile({ top: "50%" })}
`;

const BannerOverlay = (imageUrl) => {
    return (
        <Header>
            <Image src={imageUrl.imageUrl} alt="About" />
            <Section>
                <Title>{imageUrl.title}</Title>
            </Section>
        </Header>
    );
};

export default BannerOverlay;
